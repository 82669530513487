import { MenuItem } from './menu.model';

export const MENUCOURSIER: MenuItem[] = [
  {
    id: 1,
    label: 'Coursier',
    isTitle: true,
  },



  {
    id: 5,
    label: 'Colis à livrer',
    link: '/coursier/shipment-of-coursier-a-livrer',
    icon: 'fas fa-box-open',

  },

  {
    id: 5,
    label: 'Mes paiements',
    link: '/coursier/list-paiement-coursier',
    icon: 'fas fa-box-open',

  },


];
