<header id="page-topbar">
  <div class="navbar-header" >
    <div class="d-flex ">
      <!-- LOGO -->
      <div class="navbar-brand-box  ">  <!-- bg-white -->


        <a href="/" class="logo logo-light ">
          <span class="logo-sm">
            <!-- <img src="assets/images/logo-v2.png"  width= 56px height= 56px> -->
          </span>
          <span class="logo-lg" style="background-color: transparent;">
            <!-- <img src="assets/images/guepex-logo.png"  width= 170px height= 50px> -->
            <img src="assets/images/octal_blanc.png"  width= 190 height= 60>
          </span>
        </a>
      </div>

      <button mdbBtn type="button" class="btn btn-sm px-3 font-size-24 header-item waves-effect text-dark" 
      id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="ri-menu-2-line align-middle"></i>
      </button>

      <!-- App Search-->

        <form [formGroup]="searchForm" class="app-search mt-2 d-none d-lg-block" (ngSubmit)="searchTracking()">
          <div class="position-relative">
            <input type="text" class="form-control" placeholder="Tracking oct-xxxxxxxx" formControlName='search' 
 
            [ngClass]="{'is-invalid': searchForm.controls['search'].invalid && (searchForm.controls['search'].dirty 
            || searchForm.controls['search'].touched),'is-valid': searchForm.controls['search'].valid && 
            (searchForm.controls['search'].dirty 
            || searchForm.controls['search'].touched) }">
            <span class="ri-search-line"></span>
          </div>
        </form>


    </div>

    <div class="d-flex">

      <div class="dropdown d-inline-block d-lg-none ml-2" ngbDropdown>
        <button mdbBtn type="button" ngbDropdownToggle class="btn header-item noti-icon waves-effect"
          id="page-header-search-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="ri-search-line"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
          aria-labelledby="page-header-search-dropdown" ngbDropdownMenu>

          <form class="p-3" [formGroup]="searchForm">
            <div class="form-group m-0">
              <div class="input-group">
                
                <input type="text" class="form-control" placeholder="Tracking oct-xxxxxxxx" formControlName="search1">
                  <button class="btn btn-primary btn-md mt-0 " type="submit"><i class="ri-search-line"></i></button>
              </div>
            </div>
          </form>
        </div>
      </div>

 


      <!-- <div class="dropdown d-none d-lg-inline-block ml-1">
        <button mdbBtn type="button" class="btn header-item noti-icon waves-effect custumBtn" (click)="fullscreen()">
          <i class="ri-fullscreen-line"></i>
        </button>
      </div> -->

     

      <div class="dropdown d-inline-block user-dropdown" ngbDropdown>
        <button mdbBtn type="button" ngbDropdownToggle class="btn header-item waves-effect custumBtn" id="page-header-user-dropdown"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="far fa-user rounded-circle text-dark" alt="Header Avatar"></i>
          <span class="d-none text-dark d-xl-inline-block ml-1">{{username}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>

        <div class="dropdown-menu dropdown-menu-right " ngbDropdownMenu>
          <!-- item-->
          <!-- <a class="dropdown-item" href="javascript: void(0);"><i class="ri-user-line align-middle mr-1"></i>
            {{ 'HEADER.LOGIN.PROFILE' | translate}}</a>
          <a class="dropdown-item" href="javascript: void(0);"><i class="ri-wallet-2-line align-middle mr-1"></i>{{
            'HEADER.LOGIN.MY_WALLET' | translate}}</a>
          <a class="dropdown-item d-block" href="javascript: void(0);"><span
              class="badge badge-success float-right mt-1">11</span><i
              class="ri-settings-2-line align-middle mr-1"></i>{{ 'HEADER.LOGIN.SETTINGS' | translate}} </a>
          <a class="dropdown-item" href="javascript: void(0);"><i class="ri-lock-unlock-line align-middle mr-1"></i>
            {{ 'HEADER.LOGIN.LOCK_SCREEN' | translate}}</a> -->
          <!-- <div class="dropdown-divider"></div> -->
          <p class=" text-muted px-4 m-0 py-0 "  >
          {{email}}</p>
          <p class=" text-muted px-4 m-0 py-0"  >
          {{fonction}}</p>
          <p class=" text-muted px-4 mt-0 pt-0"  >
          {{station}}</p>
          <a class="dropdown-item text-danger " href="javascript: void(0);" (click)="logout()"><i
              class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            Déconnexion</a>
        </div>
      </div>

      <!-- <div class="dropdown d-inline-block">
        <button mdbBtn type="button" class="btn header-item noti-icon right-bar-toggle waves-effect"
          (click)="toggleRightSidebar()">
          <i class="ri-settings-2-line"></i>
        </button>
      </div> -->

    </div>
  </div>
</header>
