<div>
    <div class="row mb-3 col-12 align-center text-center d-flex justify-content-between">
        

        <form [formGroup]="clientSelected" class="row col-12" *ngIf="!clientLoaded; else autreClient">
            <h4 class="labtrack mt-1 mx-2"><i class="bi bi-collection "></i>Remettre colis retour au client</h4>
            <div class="col-3">
            <ng-select appendTo="body" formControlName="clientSelect" placeholder=" Selectionner un client" [clearable]="false"
                (change)="getColisClientRetourInformation($event)" [readonly]="clientLoaded">
                <ng-option *ngFor="let client of client" [value]="client">
                    <span class="text-uppercase">{{client.nom}}</span>
                </ng-option>
            </ng-select>
            </div>
        </form>
        <ng-template #autreClient>
            <div class="py-0">
                <button type="button" class="btn btn-md btn-dark text-center mt-0" (click)="changeClient()">
                    <i class="fas fa-arrow-left"></i> Autre client</button>
            </div>
                <h4 class=" p-0 px-3 rounded text-capitalize ">{{ctl.nom}}: <span>{{nbrColis}} colis</span>
                </h4>
        </ng-template>

    </div>
    <hr class=" mx-auto mt-0 p-0">
    <div class="col-12 col-md-8 mx-auto text-center" style="background:transparent;" *ngIf="listColisOfClient.length > 0">
        <form [formGroup]="formdata">
            <div class="form-group  col-12 col-md-10 mx-auto d-flex m-0 ">
                <input type="text" formControlName="tracking" class="form-control mr-1 mt-1 shadow" 
                    placeholder="Tracking xxxxxxxx" 
                    autofocus>
                <input type="submit" class="btn btn-info shadow px-4 mx-1" (click)="checkColis()"
                 value="ajouter">
            </div>
        </form>
        <hr class="mx-auto">
    </div>
    <div class=" text-dark">
        <label class='m-3 p-2 bg-info text-white' *ngFor="let i of selected">{{i}}
            <!-- <i class="fas fa-times  mx-2 text-danger" (click)="deletselected(i)"></i> -->
        </label>
    </div>
    <div class="mx-auto text-center mt-5" *ngIf="selected.length > 0">
        <button type="submit" class="btn btn-success shadow btn-lg  px-4 mx-1"
         (click)="receptShipmentClient()">Remettre la selection
        </button>
    </div>
    <p class="text-center mx-auto mt-1 display-1" *ngIf = "listColisOfClient.length > 0">
        {{count}}
    </p>
</div>