<div id="panel" class="col-12 col-sm-10 col-md-8  mx-auto text-center" style="background:transparent;">
  <form [formGroup]="formdata">
    <h4 class="labtrack "><i class="bi bi-collection "></i>Expédition</h4>
    <div class="form-groupe col-12 col-lg-10 mx-auto d-flex m-0 ">
      <input mdbInput type="text" formControlName="tracking" class="form-control mr-1 shadow mt-1" placeholder="oct-xxxxxxxx" autofocus >
      <input mdbBtn type="submit" color="primary" class="btn btn-info shadow px-4 mx-1 waves-light" (click)="recieveShipments()" value="ajouter">
    </div>
  </form>
</div>
<hr class="mx-auto" style="width: 90%">
<div class=" text-dark">
  <label class='m-3 p-2 bg-info text-white' *ngFor="let i of arr">{{i}}<i class="fas fa-times  mx-2 text-danger"
      (click)="deletSelectedShipment(i)"></i></label>
</div>
<div class="mx-auto text-center mt-5" *ngIf="count>0">
  <button  type="submit" class="btn btn-success shadow btn-lg  px-4 mx-1"  (click)="setShipmentExpedier()">Valider
    l'expédition
  </button>
  <span class="my-0 text-danger">
    <h2>{{count}}</h2>
  </span>
</div>
