import { MenuItem } from './menu.model';

export const MENUOPS: MenuItem[] = [
  {
    id: 1,
    label: 'Menu',
    isTitle: true,
  },
  {
    id: 2,
    label: 'Tableau de bord',
    icon: 'ri-dashboard-line',
    link: '/operations',
  },
  {
    id: 4,
    label: 'Expédition',
    link: '/operations/receive-shipment',
    icon: 'fas fa-box-open',
  },

  {
    id: 5,
    label: 'Liste des colis',
    link: '/operations/list-shipments',
    icon: 'fas fa-boxes',
  },
  {
    id: 6,
    label: 'Retour',
    icon: 'fas fa-reply-all',
    subItems: [
      {
        id: 7,
        label: 'Retour entreprise',
        link: '/operations/receive-retunshipment',
        parentId: 6,
      },
      {
        id: 8,
        label: 'Remettre colis client',
        link: '/operations/retour/affectRetourToClient',
        parentId: 6,
      },
      {
        id: 25,
        label: 'Liste sacs',
        link: '/operations/listesacs',
        parentId: 7,
      },
    ]
  },


  {

    id: 19,
    label: 'Affecté au corsier',
    icon: 'fas fa-box-open',
    link: '/operations/affecter-au-coursier',



  },

];
