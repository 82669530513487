<!-- <app-pagetitle title="liste des colis" [breadcrumbItems]="breadCrumbItems"> </app-pagetitle> -->
<h5>Liste des colis</h5>
<div class="row col-12 mb-3 ml-1 d-flex justify-content-end mt-2" style="margin-top: -24px">

  <button class="btn btn-outline-success" (click)="exportShipments(searchShipmentTerm)">
    <i class="fas fa-file-excel">
      <span class="mt-1 font-weight-normal pl-1">Exporter</span>
    </i>
  </button>
  <button *ngIf="colisToPrint.length > 1" type="button" mdbBtn class="btn btn-md btn-outline-danger waves-effect waves-light "
    (click)="printMultipleBordereauOctal()"><i class="fas fa-print"></i> Imprimer</button>


</div>


<div class="row">
  <form [formGroup]="filterForm" class="row col-12 mb-3 pb-0 mx-auto  filterPers mx-auto">
    <div class="row col-xl-12 pt-2 pb-0 px-0 mx-0">


      <div class="form-group  col-12 py-0 my-0 col-12 col-md-6 col-lg-3 mx-0 px-0">
        <label class="col-form-label ml-3 text-right"> Boutique </label>
        <div class="col-12 col-md-10 text-left">
          <ng-select formControlName="clientSelect" placeholder=" Selectionner la boutique">

            <ng-option  *ngFor="let client of clients" [value]="client.id">
              <span class="text-uppercase" > 
                {{client.nom}} 
              </span>
            </ng-option>
     
          </ng-select>
        </div>
      </div>

      <div class="form-group  col-12 py-0 my-0 col-12 col-md-6 col-lg-3 mx-0 px-0">
        <label class="col-form-label ml-3 text-right"> Société </label>
        <div class="col-12 col-md-10 text-left">
          <ng-select formControlName="entreprise" placeholder=" Selectionner la société">
            <ng-option value="octal">OCTAL EXPRESS </ng-option>
            <ng-option value="yalidine">YALIDINE EXPRESS </ng-option>
            <ng-option value="noest">NOEST </ng-option>
          </ng-select>
        </div>
      </div>


      <div class="form-group  col-12 py-0 my-0 col-12 col-md-6 col-lg-3 mx-0 px-0">
        <label class="col-form-label ml-3 text-right"> Statut </label>
        <div class="col-12 col-md-10 text-left">
          <ng-select formControlName="status" placeholder=" Selectionner le statut">
            <ng-option *ngFor="let statut of keysStatusShipmentEnum()" [value]="statusShipmentEnum[statut]">
              {{statusShipmentEnum[statut]}}
            </ng-option>
          </ng-select>
        </div>
      </div>

      <div class="form-group  col-12 py-0 my-0 col-12 col-md-6 col-lg-2 mx-0 px-0">
        <label class="col-form-label ml-3 text-right"> Type livraison </label>
        <div class="col-12 col-md-10 text-left">
          <ng-select formControlName="typeLivraison" placeholder="Type de livraison">
            <ng-option value="domicile">DOMICILE </ng-option>
            <ng-option value="stopDesk">STOP DESK </ng-option>

          </ng-select>
        </div>
      </div>
      <div class="form-group col-12 col-lg-6 col-xl-1 mx-0 px-0 my-0">
        <label class="col-form-label ml-3 text-right"> </label>
        <div class=" mx-auto text-center px-0 mt-3">
          <button class="btn btn-md btn-danger mt-0 mb-2 text-center" (click)="getPaginationShipments()"
            [disabled]="filterForm.invalid">
            Filtrer
          </button>
        </div>
      </div>
    </div>
  </form>

  <div class="col-12">
    <div class="card">
      <div class="card-body">

        <div class="row mb-md-2">
          <!-- Search -->
          <div class="col-sm-12 col-md-12">
            <div id="tickets-table_filter" class="dataTables_filter text-md-right">
              <label class="d-inline-flex align-items-center">Recherche:
                <input type="text" name="searchTerm" class="form-control form-control-sm ml-2"
                  aria-controls="tickets-table" [(ngModel)]="searchShipmentTerm"
                  (ngModelChange)="searchTermUpdate(searchShipmentTerm)" /></label>
            </div>
          </div>
          <!-- End search -->
        </div>

        <!-- Table -->
        <div class="table-responsive">
          <table id="basic-datatable"
            class="table table-centered dt-responsive nowrap no-footer dtr-inline table-hover hover">
            <thead>
              <tr>
                <th>tracking</th>
                <th>Expéditeur</th>
                <th>Destinataire</th>
                <th>dernier statut</th>
                <th>Action</th>

              </tr>
            </thead>
            <tbody *ngIf="!isLoading; else spiner">

              <tr *ngFor="let shipment of shipmentData; let i=index " class="hover">

                <td class="text-uppercase"><input type="checkbox" (change)="onCheckboxChange($event,shipment.tracking)" [value]="shipment.tracking" >{{shipment?.tracking}}
                  <p style="font-size: 10px">
                    {{shipment?.entreprise}} </p>
                </td>
                <td class="text-capitalize">
                  {{ shipment?.createdBy?.username }}
                  <p style="font-size: 10px" class="text-uppercase ">
                    {{shipment?.createdBy?.client?.nomCommercial}}
                  </p>
                </td>
                <td class="text-capitalize">
                  {{ shipment?.firstname }} {{ shipment?.familyname }}
                </td>

                <td>
                  {{shipment?.lastStatus}}
                  <p style="font-size: 10px" class="text-uppercase ">
                    {{shipment?.dateLastStatus | date:'dd-MM-YYYY HH:mm:ss'}}
                  </p>
                </td>
                <td class="py-0">
                  <button mdbBtn color="mdb-color" class=" btn-sm btn " (click)="print(shipment.tracking)"
                    mdbWavesEffect><i class="fas fa-print"></i></button>
                  <button mdbBtn class=" btn-sm btn btn-primary" (click)="detailColis(shipment.tracking)"
                    mdbWavesEffect><i class="fa fa-info-circle font-size-8" aria-hidden="true"></i></button>
                </td>
              </tr>
              <tr *ngIf="shipmentData.length <= 0">
                <th colspan="7" class="text-center">
                  <div class="text-center">
                    <p class="m-0 p-0"><i class="ri-error-warning-line customIcone"> </i> Pas
                      de shipments ! </p>
                  </div>
                </th>
              </tr>
            </tbody>

            <ng-template #spiner>
              <tbody>
                <tr>
                  <th colspan="7" class="text-center">
                    <div class="spinner-border text-primary m-1" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <span class="d-block">Chargement ...</span>
                  </th>
                </tr>
              </tbody>
            </ng-template>
          </table>
        </div>
        <!-- End table -->

        <div class="row justify-content-md-between align-items-md-center mt-2">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
              Total {{ metaData.totalItems }} shipments
            </div>
          </div>
          <!-- Pagination -->
          <div class="col-sm-12 col-md-5">
            <nav aria-label="...">
              <ul class="pagination justify-content-end">
                <!-- <li class="page-item">
                                        <button class="page-link info">
                                            total utilistaeur : {{ metaData.totalItems }}
                                        </button>
                                    </li> -->
                <li class="page-item">
                  <button class="page-link" [disabled]="metaLinks.previous.length <= 0"
                    (click)="funcPaginate(metaLinks.previous)">
                    <span aria-hidden="true">&laquo;</span>
                    <span class="sr-only">Previous</span>
                  </button>
                </li>
                <li class="page-item">
                  <button class="page-link" *ngIf="metaData.currentPage - 2 > 0"
                    (click)="funcPaginate('', metaData.currentPage - 2)">
                    {{ metaData.currentPage - 2
                    }}<span class="sr-only"></span>
                  </button>
                </li>
                <li class="page-item">
                  <button class="page-link" *ngIf="metaData.currentPage - 1 > 0"
                    (click)="funcPaginate('', metaData.currentPage - 1)">
                    {{ metaData.currentPage - 1
                    }}<span class="sr-only"></span>
                  </button>
                </li>
                <li class="page-item active">
                  <button class="page-link" (click)="funcPaginate('', metaData.currentPage)">
                    {{ metaData.currentPage
                    }}<span class="sr-only">(current)</span>
                  </button>
                </li>
                <li class="page-item">
                  <button class="page-link" (click)="funcPaginate('', metaData.currentPage + 1)"
                    *ngIf="metaData.currentPage + 1 <= metaData.totalPages">
                    {{ metaData.currentPage + 1
                    }}<span class="sr-only"></span>
                  </button>
                </li>
                <li class="page-item">
                  <button class="page-link" (click)="funcPaginate('', metaData.currentPage + 2)"
                    *ngIf="metaData.currentPage + 2 < metaData.totalPages">
                    {{ metaData.currentPage + 2
                    }}<span class="sr-only"></span>
                  </button>
                </li>
                <li class="page-item">
                  <button class="page-link" *ngIf="
                    metaLinks.next.length != 0 &&
                    metaData.currentPage + 2 <= metaData.totalPages
                  ">
                    ...
                  </button>
                </li>
                <li class="page-item">
                  <button class="page-link" [disabled]="metaLinks.next.length <= 0"
                    (click)="funcPaginate(metaLinks.next)">
                    <span aria-hidden="true">&raquo;</span>
                    <span class="sr-only">Next</span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
          <!-- End Pagination -->
        </div>
      </div>
    </div>
  </div>
</div>