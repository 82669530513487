import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
user = null
  constructor(private authService:AuthService) { }

  ngOnInit(): void {
    this.user =this.authService.currentUser
    console.log("🚀 ~ file: dashboard.component.ts:15 ~ DashboardComponent ~ ngOnInit ~  this.user:",  this.user)
  }

}
