<div>

  <div class="row mb-3 col-12 align-center text-center">
    <h4 class="labtrack mt-1 mr-2"><i class="bi bi-collection "></i>Recevoir le retour des entreprises </h4>

  </div>

  <hr class=" mx-auto">
  <div class="col-12 col-md-8 mx-auto text-center" style="background:transparent;">

    <form [formGroup]="formdata">
      <div class="form-groupe col-10 mx-auto d-flex m-0 ">

        <input type="text" formControlName="tracking" class="form-control mr-1 mt-1 shadow"
          placeholder="Tracking oct-xxxxxxxx" autofocus>
        <input type="submit" class="btn btn-info shadow px-2 py-2  mx-1" (click)="fillList()" value="ajouter">
      </div>
    </form>
    <hr class="mx-auto">
  </div>
  <div class=" text-dark">
    <label class='m-3 p-2 bg-info text-white' *ngFor="let i of selected">{{i}}
      <i class="fas fa-times  mx-2 text-danger" (click)="deletselected(i)"></i>
    </label>
  </div>
  <div class="mx-auto text-center mt-5" *ngIf="selected.length > 0">
    <button type="submit" class="btn btn-success shadow btn-lg  px-4 mx-1" (click)="setShipmentReturnFromEntreprise()">
      Valider la reception de retour
    </button>
  </div>
  <p *ngIf="count > 0 " class="text-center text-muted mx-auto mt-1 display-2">
    {{count}}
  </p>

</div>




















<!-- <div id="panel" class="col-12 col-sm-10 col-md-8  mx-auto text-center" style="background:transparent;">
  <form [formGroup]="aspireForm">
    <h4 class="labtrack "><i class="bi bi-collection "></i>Recevoir le retour des entreprises</h4>
    <div class="container">
      <div class="row">
        <div class="col-md-10 mx-auto">
 
            <div class="form-group files">
              <ng-select  formControlName="company" placeholder="Selectionner l'entreprise" (change)="changeCompany()">
                <ng-option  value="yalidine" >
                  YALIDINE
                </ng-option>
                <ng-option  value="noest" >
                  NOEST
                </ng-option>
              </ng-select>  
              <input [disabled]="aspireForm.get('company').invalid" (change)="addfile($event)" formControlName="fichier" type="file" class="form-control" multiple="">
            </div>

    
        </div>

      </div>
    </div>
  </form>
</div>

<div class="mx-auto text-center mt-5" >
  <button type="submit" class="btn btn-success shadow btn-lg  px-4 mx-1"
  [disabled]="!aspirationValide()"
    (click)="setShipmentReturnFromEntreprise()">Valider la reception de retour
  </button>
  <span class="my-0 text-danger">
    <h2>{{count}}</h2>
  </span>
</div>
<hr class="mx-auto" style="width: 90%"> -->