import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ShipmentsOpsService } from '../shipments-ops.service';
import { SweetAlertService } from 'src/app/core/services/sweet-alert.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-receive-retour-from-entreprise',
  templateUrl: './receive-retour-from-entreprise.component.html',
  styleUrls: ['./receive-retour-from-entreprise.component.scss']
})
export class ReceiveRetourFromEntrepriseComponent implements OnInit {
  selected: string[] = [];
  agence: any[] = []
  listeColisRetourFromAgence: string[] = [];
  lastSac=null;
  count: number = 0;
  formdata: FormGroup;
  agenceSelected: FormGroup
  constructor(
    private shipmentService: ShipmentsOpsService ,
    public sweetalertService: SweetAlertService
    
  ) { 
  }

  ngOnInit(): void {

    this.formdata = new FormGroup({
      tracking: new FormControl()
    })
    this.getColisRetourEntreprises()

  }
  fillList() {
    const express_reg = new RegExp(/^(oct|ech)-\d{8}$/i);
    const track = this.formdata.value["tracking"];
    console.log(track)
    if (!express_reg.test(track)) {
      this.sweetalertService.backgroundRed()
      this.formdata.reset()
    } else if (!this.listeColisRetourFromAgence.includes(track.toLowerCase())) {
      this.sweetalertService.backgroundRed()
      this.formdata.reset()
    } else {
      if((RegExp(/^(ech)-\d{8}$/i)).test(track)){
        const alertTitle = 'Confirmation!!';
        const alertMessage = "Confirmez vous cet échange?"
        this.sweetalertService
          .confirmStandard(alertTitle, alertMessage, '', '', null)
          .then((result) => {
            if (result.isConfirmed) {
              const index = this.listeColisRetourFromAgence.indexOf(track.toLowerCase());
            console.log(index)
            if (index > -1) {
              this.listeColisRetourFromAgence.splice(index, 1);
              this.count += 1
              this.selected.push(track)
              this.formdata.reset()
              this.sweetalertService.backgroundGreen()
              console.log('last', this.listeColisRetourFromAgence)
            } else {
              this.sweetalertService.basicWarning("Verifier les informations")
            }
            }
            
          });
    }else{
      const index = this.listeColisRetourFromAgence.indexOf(track.toLowerCase());
      console.log(index)
      if (index > -1) {
        this.listeColisRetourFromAgence.splice(index, 1);
        this.count += 1
        this.selected.push(track)
        this.formdata.reset()
        this.sweetalertService.backgroundGreen()
        console.log('last', this.listeColisRetourFromAgence)
      } else {
        this.sweetalertService.basicWarning("Verifier les informations")
      }
    }
    }
  }
  setShipmentReturnFromEntreprise() {
    if (this.selected.length <= 0) {
      this.sweetalertService.basicWarning("Oops c'est vide")
    } else {

      this.shipmentService.setShipmentReturnFromEntreprise(this.selected)
        .subscribe(resp => {
          if (resp) {
            
        this.sweetalertService.creationSucces("Colis reçus avec succes")
            this.selected = [];
            this.count = 0;
            this.formdata.reset()
            this.getColisRetourEntreprises()
          }
        });

    }
  }



  getColisRetourEntreprises() {
    this.shipmentService.getColisRetourEntreprises().then(resp => {
         this.listeColisRetourFromAgence = resp;
    },
    (error)=> {
      this.sweetalertService.modificationFailure(error.response.error)
      this.listeColisRetourFromAgence = []
    }
    )
  }
  deletselected(i) {
    this.selected.splice(this.selected.indexOf(i.toLowerCase()), 1)
    this.listeColisRetourFromAgence.push(i.toLowerCase());
    this.count--
  }
}





















//   aspireForm: FormGroup;
//   totalShipmentsAaspirer = []
//   count: number = 0;
//   file: File;
//   arrayBuffer: any;
//   constructor(
//     private ShipmentOpsService: ShipmentsOpsService,
//     private sweetAlertService: SweetAlertService,
//     public formBuilder: FormBuilder,
//   ) {
  
//   }
//   ngOnInit(): void {
//     this.aspireForm = this.formBuilder.group({
//       fichier: new FormControl({value:null,disabled:true},  Validators.compose([Validators.required])),
//       company: new FormControl(null, Validators.compose([Validators.required])),
//     })
//   }



//   setShipmentReturnFromEntreprise() {
//     if (this.totalShipmentsAaspirer.length <= 0) {
//       this.sweetAlertService.sipmleAlert('info', 'Verifier', "Oops c'est vide");
//     } else {
//       const expidie = this.ShipmentOpsService.setShipmentReturnFromEntreprise(this.aspireForm.controls['company'].value,this.totalShipmentsAaspirer).subscribe({
//         next: (resp:any)=>{
//           console.log('responsas',resp)

//           if (resp == true) {
//             this.sweetAlertService.sipmleAlert(
//               'success',
//               'Valider',
//               'Validé avec succes'
//             );
//             this.count = 0;
//             this.aspireForm.controls['fichier'].setValue(null)
//             this.aspireForm.controls['company'].setValue(null)
//             this.aspireForm.controls['fichier'].disable()
  
//             this.totalShipmentsAaspirer=[]
//           } else {
//             this.count = 0;
//             this.aspireForm.controls['fichier'].setValue(null)
//             this.aspireForm.controls['company'].setValue(null)
//             this.aspireForm.controls['fichier'].disable()
//             this.sweetAlertService.sipmleAlertConfirme('warning', 'Oops! des colis sont pas ajouter',
//               ` Veillez verifier  ${resp.length} tracking(s): ` + resp.join(';').toString(), true)
            
  
//           }
       
       
//         },
//         error:()=>{
//           this.sweetAlertService.sipmleAlert(
//             'danger',
//             'ERROR',
//             'Verifier vos données'
//           );
//         }
//       });
//       if (expidie) {
     
//       }
//     }
//   }



//   async addfile(event) {
//     console.log("🚀 ~ file: shippments.component.ts ~ line 351 ~ addfile ~ event", event)
//     var bstr: string;
//     this.file = event.target.files[0];
//     let fileReader = new FileReader();
//     fileReader.readAsArrayBuffer(this.file);
//     fileReader.onload = (e) => {
//       this.arrayBuffer = fileReader.result;
//       var data = new Uint8Array(this.arrayBuffer);
//       var arr = new Array();
//       for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
//       bstr = arr.join("");
//       this.getShipments(bstr).then(data => {
//         console.log("🚀 ~ ReceiveRetourFromEntrepriseComponent ~ this.getShipments ~ data:", data)
//         this.totalShipmentsAaspirer = data
//         // this.formdata.value['fichier'] = data
//       })
//     }
//   }

//   async getShipments(bstr) {
//     var trackingsList: any[] = [];
//     var workbook = XLSX.read(bstr, { type: "binary" });
//     var first_sheet_name = workbook.SheetNames[0];
//     var worksheet = workbook.Sheets[first_sheet_name];

//     var states = XLSX.utils.sheet_to_json(worksheet, { raw: true, defval: ' ' });
//     //console.log(states[0]['Nom']);

//     if (this.aspireForm.get('company').value == 'yalidine') {
//       let i = 0;
//       states.forEach(element => {
//         i += 1;
//         trackingsList.push(element['Tracking']);
//         this.count+=1
//       });
//       return trackingsList;
//     }else if(this.aspireForm.get('company').value == 'noest'){
//       let i = 0;
//       states.forEach(element => {
//         i += 1;
//         trackingsList.push(element['Tracking']);
//         this.count+=1
//       });
//       return trackingsList;
//     }
//   }
//   changeCompany(){
//     if(this.aspireForm.controls['company'].valid){
//       this.aspireForm.controls['fichier'].enable()
//       this.aspireForm.controls['fichier'].setValue(null)
//     }else{
//       this.aspireForm.controls['fichier'].setValue(null)
//       this.aspireForm.controls['fichier'].disable()
//     }
//   }
//   aspirationValide(): boolean {
 
//     if (this.aspireForm.controls['fichier'].valid && this.aspireForm.controls['company'].valid) {
//       return true
//     }
//     return false
//   }
// }

