import { MenuItem } from './menu.model';

export const MENUFINANCE: MenuItem[] = [
  {
    id: 1,
    label: 'Finance',
    isTitle: true,
  },
  {
    id: 2,
    label: 'Tableau de bord',
    icon: 'ri-dashboard-line',
    link: '/finance',
  },

  {
    id: 3,
    label: 'Paiement',
    icon: 'fas fa-money-bill-wave',
    subItems: [
      {
        id: 4,
        label: 'Recever Paiements',
        link: '/finance/recevoir-paiements-entreprises',
        parentId: 3,
      },
      {
        id: 5,
        label: 'Liste paiements reçus',
        link: '/finance/liste-recoltes-entreprise',
        parentId: 3,
      },
      {
        id: 6,
        label: 'Récolte coursier',
        link: '/finance/recolte-coursier',
        parentId: 3,
      },

      {
        id: 7,
        label: 'Liste récoltes coursier',
        link: '/finance/liste-recoltes-coursier',
        parentId: 3,
      },
    
  
      {
        id: 8,
        label: 'Payer client',
        link: '/finance/payer-client',
        parentId: 3,
      },
      {
        id: 9,
        label: 'Liste paiements client',
        link: '/finance/list-paiements-client',
        parentId: 3,
      },
      {
        id: 10,
        label: 'Payer coursier',
        link: '/finance/payer-coursier',
        parentId: 3,
      },
      {
        id: 11,
        label: 'Liste paiements coursier',
        link: '/finance/list-paiements-coursier',
        parentId: 3,
      },
    ],
  },

];
