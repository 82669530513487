import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Pagination } from 'src/app/core/interfaces/paginations/pagination';
import { environment } from 'src/environments/environment';
import { ICoursier } from './affecter-au-coursier/i-coursier';

@Injectable({
  providedIn: 'root',
})
export class ShipmentsOpsService {

  observAddShipments = new BehaviorSubject<number>(0);
  constructor(private http: HttpClient) { }




  getTrackingPresExp() {
    return this.http.get<string[]>(`${environment.apiV1}shipments/getPresExp`);
  }

  setShipmentExpedier(tracking: string[]) {
    return this.http 
      .post(`${environment.apiV1}shipments/setShipmentExpedier`, {
        tracking: tracking,
      })
    
  }
  setShipmentReturnFromEntreprise(trackings: string[]) {
    return this.http 
      .post(`${environment.apiV1}shipments/setShipmentReturnFromEntreprise`, {
        trackings: trackings,
      })
    
  }

  

  async getListClientsAttachedToMyStation() {
    return await this.http
      .get<any>(`${environment.apiV1}clients/getListClientsAttachedToMyStation`)
      .toPromise();
  }
  


  printBordereauByTracking(tracking: string) {
    return this.http.get(`${environment.apiV1}shipments/printBordereauOctal/${tracking}`, {
      responseType: 'blob',
    })
  }
 

  getCoursiersByStation(): Observable<ICoursier[]> {
    return this.http.get<ICoursier[]>(
      `${environment.apiV1}coursier/coursierByStation`
    );
  }
  getShipmentsPresLivraison(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiV1}shipments/getShipmentsPresLivraison`
    );
  }

  assignToCourier(selected: string[], coursierSelected: number) {
    return this.http.post(
      `${environment.apiV1}shipments/assignToCourier`,
      {
        trackings: selected,
        coursier: coursierSelected,
      }
    );
  }

  ValiderRemiseRetourClient(selected: string[], idClient: any) {
    return this.http.post(
      `${environment.apiV1}sac/receptShipmentClient`, {
      trackings: selected,
      idClient: idClient,
    }, { responseType: 'blob' })
  }
  getColisClientRetourInformation(idClient: any) {
    return this.http.get<string[]>(
      `${environment.apiV1}shipments/getColisClientRetourInformation/${idClient}`
    );
  }

  
  getPaginateShipments(form:any): Observable<Pagination<any>> {
    console.log("🚀 ~DDDDDDDDDDD ShipmentsOpsService ~ getPaginateShipments ~ form:", form)
    return this.http.get<Pagination<any>>(`${environment.apiV1}shipments/paginateAllShipments?client=${form.clientSelect}&entreprise=${form.entreprise}&status=${form.status}&typeLivraison=${form.typeLivraison}`)
  }

  searchShipments(searchShipmentsTerm: string, form:any): Observable<Pagination<any>> {
    return this.http.get<Pagination<any>>(`${environment.apiV1}shipments/paginateAllShipments?searchShipmentsTerm=${searchShipmentsTerm}&client=${form.clientSelect}&entreprise=${form.entreprise}&status=${form.status}&typeLivraison=${form.typeLivraison}`)
  }
  funcPaginate(
    link?: string,
    page?: number,
    search?: string,
    form?:any
  ): Observable<Pagination<any>> {
    if (page && search == undefined) {
      link = `${environment.apiV1}shipments/paginateAllShipments?page=${page}&client=${form.clientSelect}&entreprise=${form.entreprise}&status=${form.status}&typeLivraison=${form.typeLivraison}`;
      return this.http.get<Pagination<any>>(link)
    }
    if (page && search) {
      link = `${environment.apiV1}shipments/paginateAllShipments?searchShipmentTerm=${search}&page=${page}&client=${form.clientSelect}&entreprise=${form.entreprise}&status=${form.status}&typeLivraison=${form.typeLivraison}`;
      return this.http.get<Pagination<any>>(link)
    }
    if (search) {
      return this.http.get<Pagination<any>>(`${link}&searchShipmentTerm=${search}&client=${form.clientSelect}&entreprise=${form.entreprise}&status=${form.status}&typeLivraison=${form.typeLivraison}`);
    }
    return this.http.get<Pagination<any>>(`${link}&client=${form.clientSelect}&entreprise=${form.entreprise}&status=${form.status}&typeLivraison=${form.typeLivraison}`);
  }

  ExportToExcel(termToSearch: string,form:any) {
    return this.http.get(`https://api.octalexpress.com/shipments/download?term=${termToSearch}$client=${form.clientSelect}&entreprise=${form.entreprise}&status=${form.status}&typeLivraison=${form.typeLivraison}`, { responseType: 'blob' });
  }


  

  printManifestSac(idSac: number) {
    return this.http.get(`${environment.apiV1}sac/printManifestSac/${idSac}`, {
      responseType: 'blob',
    });
  }

  funcPaginateSac(link?: string,
    page?: number,
    search?: string
  ): Observable<Pagination<any>> {
    if (page && search == undefined) {
      link = `${environment.apiV1}sac/paginateAllSac?page=${page}`;
      return this.http.get<Pagination<any>>(link)
    }
    if (page && search) {
      link = `${environment.apiV1}sac/paginateAllSac?searchSacTerm=${search}&page=${page}`;
      return this.http.get<Pagination<any>>(link)
    }
    if (search) {
      return this.http.get<Pagination<any>>(`${link}&searchSacTerm=${search}`);
    }
    return this.http.get<Pagination<any>>(link);
  }
  searchSacs(searchSacTerm: string) {
    return this.http.get<Pagination<any>>(`${environment.apiV1}sac/paginateAllSac?searchSacTerm=${searchSacTerm}`)

  }
  getPaginateSacs() {
    return this.http.get<Pagination<any>>(`${environment.apiV1}sac/paginateAllSac`)

  }


 
  printMultipleBordereauOctal(trackings: string[]) {
    return this.http.get(`${environment.apiV1}shipments/printMultipleBordereauOctal/${trackings}`, {
      responseType: 'blob',
    })
  }





  getColisRetourEntreprises() {
    return this.http.get<string[]>(
      `${environment.apiV1}shipments/getColisRetourEntreprises`
    ).toPromise();
  }


}

