import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AffecterAuCoursierComponent } from './components/shipments/affecter-au-coursier/affecter-au-coursier.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ReceiveShipmentComponent } from './components/shipments/receive-shipment/receive-shipment.component';
import { RemetreColisClientComponent } from './components/shipments/retour/remetre-colis-client/remetre-colis-client.component';
import { ListShipmentsComponent } from './components/shipments/list-shipments/list-shipments.component';
import { ListSacsComponent } from './components/shipments/list-sacs/list-sacs.component';
import { OperationsGuard } from '../auth/guard/operations.guard';
import { AdminGuard } from '../auth/guard/admin.guard';
import { ManagerGuard } from '../auth/guard/manager.guard';
import { CsGuard } from '../auth/guard/cs.guard';
import { CommercialGuard } from '../auth/guard/commercial.guard';
import { FinanceGuard } from '../auth/guard/finance.guard';
import { CaissierAgenceGuard } from '../auth/guard/caissier-agence.guard';
import { CaissierRegionalGuard } from '../auth/guard/caissier-regional.guard';
import { ReceiveRetourFromEntrepriseComponent } from './components/shipments/receive-retour-from-entreprise/receive-retour-from-entreprise.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [OperationsGuard]
  },
  //
  {
    path: 'receive-shipment',
    component: ReceiveShipmentComponent,
    canActivate: [OperationsGuard]
  },
  {
    path: 'receive-retunshipment',
    component: ReceiveRetourFromEntrepriseComponent,
    canActivate: [OperationsGuard]
  },

  {
    path: 'listesacs',
    component: ListSacsComponent,
    canActivate: [OperationsGuard|| AdminGuard|| ManagerGuard|| CsGuard|| CommercialGuard|| FinanceGuard|| 
      CaissierAgenceGuard|| CaissierRegionalGuard]
  },

  {
    path: 'affecter-au-coursier',
    component: AffecterAuCoursierComponent,
    canActivate: [OperationsGuard]
  },

  {
    path: 'retour/affectRetourToClient',
    component: RemetreColisClientComponent,
    canActivate: [OperationsGuard]
  },

  {
    path: 'list-shipments',
    component: ListShipmentsComponent,
    canActivate: [OperationsGuard|| AdminGuard|| ManagerGuard|| CsGuard|| CommercialGuard|| FinanceGuard|| 
    CaissierAgenceGuard|| CaissierRegionalGuard]
  },
  //

//
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OperationsRoutingModule {}
