<div *ngIf="dataLoaded" class="d-flex justify-content-md-between ">
    <h4>Détails du colis <span class="font-weight-bold text-uppercase">{{route.params.tracking}}</span></h4>

</div>
<hr class="mt-0">


<div class="row container-fluid" *ngIf="dataLoaded">
    <div class="col-12 col-lg-8 mt-1 mx-auto">
        <!-- Timeline -->
        <ul class="timeline" style="z-index: -9999999;">
            <li class="timeline-item bg-white shadow rounded ml-3 p-3  shadow" *ngFor="let d of detailColis[0]['arrayStatus']">
                <div class="timeline-arrow"></div>
                <div class="row w-100 col-12 my-0 py-0">
                    <div class="col-12 col-md-4 ">
                        <p class="font-weight-bold mb-0">{{d?.status}} </p>
                        <p>{{d?.user?d?.user: ''}}</p>
                    </div>
                    <div class="col-12 col-md-4 ">
                        <p class="text-left text-dark"><i class="fa fa-clock-o "></i>
                            {{d.date | date:'dd-MM-YYYY HH:mm:ss'}}
                            <!-- <br><span *ngIf="!isClient() && !isCoursier()">Par {{d.user}}</span> -->
                        </p>
                    </div>
                    <div class="col-12 col-md-4 ">
                        <p class="font-weight-light ml-auto text-right "><span>{{d?.station}}</span>
                            {{d?.adress?d?.adress: ''}} </p>
                            
                    </div>
                </div>
            </li>

        </ul><!-- End -->

    </div>
    <div class="col-12 col-lg-4 mt-4 d-flex bg-white shadow">
        <div class="my-3">
            <button mdbBtn color="mdb-color" class=" btn-sm btn btn-primary font-weight-bold"
                (click)="printBRD(route.params.tracking)" mdbWavesEffect>
                <i class="fas fa-print"></i> Imprimer le bordereau</button>
            <h5>Information du colis</h5>
            <div *ngFor="let clef of getObjectKeys(detailColis[0]['shipmentInformation'])"> 
                <p>
                    <span style="font-size: 15px;" class="font-weight-bold">{{clef}} </span>
                    {{detailColis[0]['shipmentInformation'][clef]}}
                </p>
            </div>
        </div>
    </div>
  
</div>


<!-- <div *ngFor="let d of detailColis[1]">
                    <label><span style="font-size: 15px;" class="badge badge-light">{{d.status}}</span>
                        le {{d.date |date:'short'}} Par {{d.user}}
                        {{d.station}} {{d.adress}}</label>
                </div>


    <div class="d-flex justify-content-md-between">
        <p class="font-weight-bold text-uppercase"><span style="font-size: 15px;" class="badge badge-secondary">Crée :
            </span>
            {{ detailColis[1][0].date | date:'dd-MM-YYYY HH:mm:ss'}}</p>
        <p class="font-weight-bold text-uppercase"><span style="font-size: 15px;" class="badge badge-success">
                {{detailColis[1][2]?detailColis[1][2].status:detailColis[1][1].status}} : </span> {{
            detailColis[1][1].date
            |date:'short'}}
        </p>
        <p class="font-weight-bold text-uppercase"><span style="font-size: 15px;" class="badge badge-info">
                {{detailColis[1][detailColis[1].length-1].status}} :</span>
            {{ detailColis[1][detailColis[1].length-1].date | date:'dd-MM-YYYY HH:mm:ss'}}
        </p>
    </div> -->