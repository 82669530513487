import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OperationsRoutingModule } from './operations-routing.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ReceiveShipmentComponent } from './components/shipments/receive-shipment/receive-shipment.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { WavesModule, MDBBootstrapModule, InputsModule, ButtonsModule } from 'angular-bootstrap-md';
import { UiSwitchModule } from 'ngx-ui-switch';
import { UiModule } from '../shared/ui/ui.module';
import { AffecterAuCoursierComponent } from './components/shipments/affecter-au-coursier/affecter-au-coursier.component';
import { RemetreColisClientComponent } from './components/shipments/retour/remetre-colis-client/remetre-colis-client.component';
import { ListShipmentsComponent } from './components/shipments/list-shipments/list-shipments.component';
import { ListSacsComponent } from './components/shipments/list-sacs/list-sacs.component';
import { NgxMaskModule } from 'ngx-mask';
import { ReceiveRetourFromEntrepriseComponent } from './components/shipments/receive-retour-from-entreprise/receive-retour-from-entreprise.component';


@NgModule({
  declarations: [
    DashboardComponent,
    ReceiveShipmentComponent,
    AffecterAuCoursierComponent,
    RemetreColisClientComponent,
    ListShipmentsComponent,
    ListSacsComponent,
    ReceiveRetourFromEntrepriseComponent
  ],
  imports: [
    CommonModule,
    OperationsRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModalModule,
    WavesModule,
    MDBBootstrapModule.forRoot(),
    InputsModule,
    ButtonsModule,
    NgSelectModule,
    UiSwitchModule,
    UiModule,
    NgxMaskModule.forRoot(),
  ]
})
export class OperationsModule { }
