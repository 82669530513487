export enum StatusShipmentEnum {

    presExpedition = 'PRÉS A EXPIDIÉ',
    expidie = 'EXPIDIÉ',
    //retour
    //Octal
    retourEntrepriseRecu='RETOUR REÇUE',
    //

    // ARetirer = 'A RETIRÉ',
    retirer = 'RETIRÉ',
    retourPayer = 'RETOUR PAYÉ',

  
 
    // livraison
    affectedToCoursier = 'AFFECTER AU COURSIER',


    echecLivraison = 'ÉCHEC LIVRAISON',
    livre = 'LIVRÉ',
  
    // echange
    enAttenteDeChangement = 'EN ATTENTE DE CHANGEMENT',
    echange = 'ECHANGÉ',
  
    // finance
    //octal
    paiementEntrepriseRecu= 'PAIEMENT REÇUE',
    //

    payer = 'PAYER',
  
    //facture
    facturer = 'FACTURÉ',
  }
  