const alertData = [
    {
        color: 'primary'
    },
    {
        color: 'secondary'
    },
    {
        color: 'success',
    },
    {
        color: 'danger',
    },
    {
        color: 'warning',
    },
    {
        color: 'info',
    }
];

export { alertData };
