import { MenuItem } from './menu.model';

export const MENUADMIN: MenuItem[] = [
  {
    id: 1,
    label: 'Menu - Admin',
    isTitle: true,
  },
  {
    id: 2,
    label: 'DASHBOARDS',
    icon: 'ri-dashboard-line',
    link: '/admin',
  },
  {
    id: 3,
    label: 'UTILISATEURS',
    icon: 'fas fa-users',
    link: '/admin/users',
    subItems: [
      {
        id: 4,
        label: 'Utilisateur',
        icon: 'fas fa-users',
        link: '/admin/users',
        parentId: 3,
      },
      {
        id: 5,
        label: 'Freelance',
        icon: 'fas fa-users',
        link: '/admin/coursier',
        parentId: 3,
      },
      {
        id: 6,
        label: 'Employé',
        icon: 'fas fa-users',
        link: '/admin/employé',
        parentId: 3,
      },

      {
        id: 7,
        label: 'Client',
        icon: 'fas fa-users',
        link: '/admin/client',
        parentId: 3,
      },
    ],
  },
  {
    id: 8,
    label: 'PARAMETRAGE',
    icon: 'ri-compass-3-line',
    link: '/admin/wilaya',
    subItems: [
      {
        id: 9,
        label: 'Wilayas',
        icon: 'ri-dashboard-line',
        link: '/admin/wilaya',
        parentId: 8,
      },
      {
        id: 10,
        label: 'Communes',
        icon: 'ri-dashboard-line',
        link: '/admin/commune',
        parentId: 8,
      },
      {
        id: 11,
        label: 'Agences',
        icon: 'ri-dashboard-line',
        link: '/admin/agence',
        parentId: 8,
      },
  
    ],
  },
  {
    id: 14,
    label: 'TARIFICATONS',
    icon: 'ri-exchange-dollar-line',
    link: '/admin/tarifications',
  },
  {
    id: 15,
    label: 'TARIFS RETOUR',
    icon: 'ri-exchange-dollar-line',
    link: '/admin/tarif-retour',
  },
];
