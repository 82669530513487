import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { SweetAlertService } from 'src/app/core/services/sweet-alert.service';
import { ShipmentsOpsService } from '../../shipments-ops.service';

@Component({
  selector: 'app-remetre-colis-client',
  templateUrl: './remetre-colis-client.component.html',
  styleUrls: ['./remetre-colis-client.component.scss']
})
export class RemetreColisClientComponent implements OnInit {
  selected: string[] = [];
  client: any[] = [];

  /**
   * list sac du client selectionner
   */


  /**
   *
   */

  /**
   * nb colis du client selectionner
   */
  nbrColis = 0;
  /**
   * nb sac du clinet selectionner
   */

  /**
   * turn to true is any sac is loaded
   */


  count: number = 0;
  formdata: FormGroup;
  clientSelected: FormGroup;
  listColisOfClient = []
  clientLoaded: boolean;
  ctl: any;

  constructor(
    private shipmentOpsService: ShipmentsOpsService,
    private sweetAlertService: SweetAlertService,
    private router: Router
  ) {
    this.clientSelected = new FormGroup({
      clientSelect: new FormControl(),
    });
    this.formdata = new FormGroup({
      tracking: new FormControl(),
    });

  }

  ngOnInit(): void {
    this.clientLoaded = false;
    this.getListClients();
  }


  checkColis() {
    const track = this.formdata.value["tracking"];
    console.log("🚀 ~ RemetreColisClientComponent ~ checkColis ~ track:", track)
    if (!this.listColisOfClient.includes(track.toLowerCase())) {
      this.sweetAlertService.backgroundRed()
      this.formdata.reset()
    } else {
      const index = this.listColisOfClient.indexOf(track.toLowerCase());
      console.log('/////////////////////////////////:', index)
      if (index > -1) {
        this.listColisOfClient.splice(index, 1);
        this.count -= 1
        this.selected.push(track.toLowerCase())
        this.formdata.reset()
        this.sweetAlertService.backgroundGreen()
        console.log('last', this.listColisOfClient)
      } else {
        this.sweetAlertService.sipmleAlert('error', "Verifier les informations", "")
      }
    }
  }
  receptShipmentClient() {
    if (this.selected.length <= 0) {
      this.sweetAlertService.sipmleAlert('info', "Oops c'est vide", "");
    } else {

      const idClient = this.clientSelected.value['clientSelect'].id;
      this.shipmentOpsService
        .ValiderRemiseRetourClient(this.selected, idClient)
        .subscribe((resp) => {
          if (resp) {
            this.openFile(resp, 'application/pdf');
            this.sweetAlertService.sipmleAlert('success', 'Validé avec succes', "");
            this.selected = [];
            this.formdata.reset();
            this.count = 0;
            this.ngOnInit()
          }
        });
    }
  }

  /**
   * liste clinent
   * @returns
   */
  getListClients() {
    this.client = [];
    this.clientSelected.reset('clientSelect');
    return this.shipmentOpsService
      .getListClientsAttachedToMyStation()
      .then(async (resp) => {
        for await (const client of resp) {
          this.client.push({
            id: client.id,
            nom: client.nomCommercial,
          });
        }
      });
  }

  /**
   * get client info sac cont and shipmnet cont
   */
  getColisClientRetourInformation(idClient) {
    this.shipmentOpsService.getColisClientRetourInformation(idClient.id).subscribe(
      (resp) => {
        if (resp.length > 0) {
          console.log("🚀 ~ RemetreColisClientComponent ~ getColisClientRetourInformation ~ resp:", resp)
          this.listColisOfClient = resp
          this.ctl = this.clientSelected.value['clientSelect'];
          this.count = resp.length
          this.nbrColis = resp.length

          this.clientLoaded = true;
        } else {
          this.sweetAlertService.basicWarning('Pas de colis retour')
          this.clientLoaded = false;
        }


      },

    );
  }

  changeClient() {
    console.log('***********');
    this.nbrColis = 0;
    this.clientLoaded = false;
    this.count = 0;
    this.getListClients();
  }




  openFile(data: any, type: string) {
    console.log(type, data);
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert('Please disable your pop-up blocker and try again!');
    }
  }
}
